<template>
  <form @submit.prevent="submit">
    <div class="row">
      <loading-notification :show="loading" />
    </div>
    <div class="row">
      <div class="flex xs12 md3">
        <va-input
          color="info"
          v-model="form.name.value"
          :disabled="loading"
          :label="$t('layout.form.nameInput')"
          :error="!!form.name.errors.length"
          :error-messages="translatedErrors(form.name.errors)"
          @blur="validate('name')"
        />
      </div>
      <div class="flex xs12 md2">
        <va-input
          color="info"
          v-model="form.alpha_code.value"
          :disabled="loading"
          :label="$t('layout.form.alpha_code')"
          :error="!!form.alpha_code.errors.length"
          :error-messages="translatedErrors(form.alpha_code.errors)"
          @blur="validate('alpha_code')"
        />
      </div>
      <div class="flex xs12 md2">
        <va-select
          v-model="selectedType"
          :label="$t('language.inputs.translation_type')"
          :options="translationsTypes"
          :no-options-text="$t('layout.empty')"
          :disabled="loading"
          key-by="value"
          text-by="name"
          searchable
        />
      </div>
      <div class="flex xs12 md2">
        <va-select
          v-model="selectedTranslation"
          :label="$t('language.inputs.base_translation')"
          :options="translations"
          :no-options-text="$t('layout.empty')"
          :disabled="loading"
          key-by="id"
          text-by="name"
          searchable
        />
      </div>
      <div class="flex xs12 md3">
        <va-button
          color="primary"
          :disabled="loading"
          icon="fa fa-upload"
          type="button"
          @click="fileTranslation"
        >
          {{ $t('language.inputs.file_translation') }}
        </va-button>
      </div>
    </div>

    <div class="row">
      <div class="flex xs12">
        <json-editor
          :options="optionsJson"
          :objData="currentTranslation"
          v-model="currentTranslation"
        />
      </div>
    </div>

    <div class="row">
      <div class="flex xs12">
        <va-button
          color="primary"
          :disabled="loading"
        >
          <text-loading
            :loading="loading"
            icon="fa fa-save"
          >
            {{ $t('layout.form.save') }}
          </text-loading>
        </va-button>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import { validatorMixin } from '@/services/validator'

export default {
  name: 'languages-form',
  props: {
    loading: {
      type: Boolean,
      default: function () { return false },
    },
    language: {
      type: Object,
      required: false,
      default: function () {
        return {
          id: 0,
        }
      },
    },
  },
  mixins: [validatorMixin],
  data () {
    return {
      translations: [],
      selectedTranslation: '',
      selectedType: '',
      currentTranslation: {},
      form: {
        name: {
          value: '',
          validate: {
            required: true,
          },
          errors: [],
        },
        alpha_code: {
          value: '',
          validate: {
            required: true,
          },
          errors: [],
        },
      },
    }
  },
  computed: {
    ...mapGetters(['currentUser', 'currentLocale']),
    translationsTypes () {
      return [
        { name: this.$t('languages.types.web'), value: 0 },
        { name: this.$t('languages.types.app'), value: 1 },
        { name: this.$t('languages.types.web_app'), value: 2 },
      ]
    },
    isNew () {
      return this.language.id === 0
    },
    optionsJson () {
      return {
        confirmText: this.$t('layout.buttons.confirm'),
        cancelText: this.$t('layout.buttons.cancel'),
      }
    },
  },
  watch: {
    language (val) {
      this.setLanguage(val)
    },
    async currentLocale (val) {
      await this.$nextTick()
      this.validateAll()
    },
    selectedTranslation (val) {
      if (val) {
        this.alertChangeBase(val)
      }
    },
  },
  created () {
    this.initialData()
    this.$nextTick(() => {
      this.validateAll()
    })
    this.selectedType = this.translationsTypes[0]
    this.initBaseTranslation()
  },
  methods: {
    async setLanguage (val) {
      this.setFormData(val)
      if (val.translation) {
        this.currentTranslation = { ...val.translation }
      }
      if (val.id) {
        await this.$nextTick()
        this.validateAll()
        this.selectedType = this.translationsTypes.find(x => x.value === val.type)
      }
    },
    initialData () {
      if (this.language) {
        this.setLanguage(this.language)
      }
    },
    async fileTranslation () {
      const { value: file } = await this.$swal({
        title: this.$t('language.modals.select_json_file'),
        input: 'file',
        inputAttributes: {
          accept: 'application/json',
          'aria-label': 'Upload your translation file',
        },
      })

      if (file) {
        const reader = new FileReader()
        reader.onload = async (e) => {
          const translation = JSON.parse(e.target.result)
          const result = await this.$swal({
            icon: 'question',
            title: this.$t('language.modals.choose_what_to_do'),
            html: this.$t('language.modals.choose_description'),
            showCancelButton: true,
            confirmButtonText: this.$t('language.inputs.merge'),
            cancelButtonText: this.$t('language.inputs.replace'),
          })

          if (result.isConfirmed) {
            this.currentTranslation = { ...this.currentTranslation, ...translation }
          } else if (result.dismiss === 'cancel') {
            this.currentTranslation = { ...translation }
          }
        }
        reader.readAsText(file)
      }
    },
    async alertChangeBase (language) {
      const result = await this.$swal({
        icon: 'warning',
        text: this.$t('languages.confirm_rebase'),
        showCancelButton: true,
        confirmButtonText: this.$t('layout.buttons.confirm'),
        cancelButtonText: this.$t('layout.buttons.cancel'),
      })
      if (result.value !== true) return

      const rebase = JSON.parse(language.translation)
      this.currentTranslation = { ...rebase, ...this.currentTranslation }
    },
    async initBaseTranslation () {
      let response = false
      try {
        response = await this.$http.get('languages')
      } catch (err) {
        this.showToast(this.$t('notifications.network.error'), {
          icon: 'fa-times',
          position: 'top-right',
          duration: 2500,
          fullWidth: false,
        })
        this.loading = false
        this.error = true
        return
      }

      const translations = response.data.data.slice(0).map(x => ({ ...x, name: this.$t(`language.${x.name}`) }))
      this.translations = translations
    },
    checkEmpty (field) {
      return field === undefined || field === null || field === ''
    },
    async submit () {
      this.validateAll()
      if (!this.formReady) return

      const language = this.getFormData(this.language)
      language.type = this.selectedType ? this.selectedType.value : 0
      language.translation = JSON.stringify(this.currentTranslation)
      this.$emit('submit', language)
    },
  },
}
</script>
